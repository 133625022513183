import { initialState } from "./state";

export const home = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_FEED_RESULT':
            return {
                ...state,
                feedResult: action.data
            }
        case 'SET_BOOKMARK_FEED_RESULT': {
            return {
                ...state,
                // Update the bookmark data for the stories
                feedResult: state.feedResult?.map(s => {
                    if (s.header === action.data.header) {
                        let res = s.data?.map((d) => {
                            if (d.id === action.data.id) {
                                return { ...d, is_liked: !d.is_liked }
                            } else {
                                return { ...d }
                            }
                        })
                        return { ...s, data: res }
                    }
                    return { ...s }
                })
            }
        }
        case 'SET_START_SESSION_TIME':
            console.log(action.data, 'action.data reducer action.data', typeof action.data)
            return {
                ...state,
                startSessionTime: new Date()
            }
        default:
            return state;
    }
};
