import { initialState } from "./state";

export const guidedPath = (state = initialState, action) => {
  switch (action.type) {
    case "SET_UNIT_LIST": {
      return Object.assign({}, state, {
        unitList: action.data,
      });
    }
    case "SET_TASK_LIST": {
      return {
        ...state,
        taskList: action.data,
      };
    }
    default:
      return state;
  }
};
