import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Loadable from "react-loadable";
import Loader from "../components/Loader/Loader";
import { persistStore } from "redux-persist";
import store from "../store";
import { PersistGate } from "redux-persist/integration/react";

const SchoolReport = Loadable({
  loader: () => import("../views/SchoolReport"),
  loading: Loader,
});
const AIRRReport = Loadable({
  loader: () => import("../views/AIRRReport"),
  loading: Loader,
});

const MeritList = Loadable({
  loader: () => import("../views/MeritList"),
  loading: Loader,
});

const ReadingOlympiad = Loadable({
  loader: () => import("../views/ReadingOlympiad"),
  loading: Loader,
});

const NotFound = Loadable({
  loader: () => import("../components/NotFound"),
  loading: Loader,
});

const IROLogin = Loadable({
  loader: () => import("../views/IROLogin"),
  loading: Loader,
});

const IROSelectTest = Loadable({
  loader: () => import("../views/IROSelectTest"),
  loading: Loader,
});

const IROPassage = Loadable({
  loader: () => import("../views/IROPassage"),
  loading: Loader,
});

const IROFeedback = Loadable({
  loader: () => import("../views/IROTypeForm"),
  loading: Loader,
});

const IROPassageReview = Loadable({
  loader: () => import("../views/IROPassageReview"),
  loading: Loader,
});

const IROCmfInstructions = Loadable({
  loader: () => import("../views/IROThirdInstruction"),
  loading: Loader,
});

const IROSpeechTestPassage = Loadable({
  loader: () => import("../views/IROSpeechTestPassage"),
  loading: Loader,
});

const IROViewReport = Loadable({
  loader: () => import("../views/IROViewReport"),
  loading: Loader,
});

const IROReportSummary = Loadable({
  loader: () => import("../views/IROReportSummary"),
  loading: Loader,
});

const IROSRLogin = Loadable({
  loader: () => import("../views/IROSRLogin"),
  loading: Loader,
});

const IROSelectReport = Loadable({
  loader: () => import("../views/IROSelectReport"),
  loading: Loader,
});

const IROReportCertificate = Loadable({
  loader: () => import("../views/IROReportCertificate"),
  loading: Loader,
});

const IROSchoolReport = Loadable({
  loader: () => import("../views/IROSchoolReport"),
  loading: Loader,
});

const IROSchoolLogin = Loadable({
  loader: () => import("../views/IROSchoolLogin"),
  loading: Loader,
});
const IROFeedbackForm = Loadable({
  loader: () => import("../views/FeedbackForm"),
  loading: Loader,
});

class RootRouter extends Component {
  constructor(props) {
    super(props);
    this.persistor = persistStore(store);
  }

  render() {
    return (
      <PersistGate loading={<div>Loading...</div>} persistor={this.persistor}>
        <Router>
          <Switch>
            <Route path="/" exact component={IROLogin} />

            <Route path="/school-report" exact component={SchoolReport} />
            <Route path="/airr-report" exact component={AIRRReport} />
            <Route path="/meritlist" exact component={MeritList} />
            <Route
              path="/international-olympiad"
              exact
              component={ReadingOlympiad}
            />
            <Route path="/iro-select-test" exact component={IROSelectTest} />
            <Route path="/iro-passage" exact component={IROPassage} />
            <Route path="/iro-feedback" exact component={IROFeedback} />
            <Route
              path="/iro-passage-review"
              exact
              component={IROPassageReview}
            />
            <Route
              path="/iro-speech-test"
              exact
              component={IROSpeechTestPassage}
            />
            <Route
              path="/iro-report-summary"
              exact
              component={IROReportSummary}
            />
            <Route path="/iro-report-login" exact component={IROSRLogin} />
            <Route
              path="/iro-select-report"
              exact
              component={IROSelectReport}
            />
            <Route path="/iro-view-report" exact component={IROViewReport} />
            <Route
              exact
              path="/iro-cmf-instructions"
              component={IROCmfInstructions}
            />
            <Route
              exact
              path="/iro-report-certificate"
              component={IROReportCertificate}
            />
            <Route
              exact
              path="/iro-school-report"
              component={IROSchoolReport}
            />
            <Route
              exact
              path="/iro-feedback-form"
              component={IROFeedbackForm}
            />

            <Route exact path="/iro-school-login" component={IROSchoolLogin} />

            <Route component={NotFound} />
          </Switch>
        </Router>
      </PersistGate>
    );
  }
}

export default RootRouter;
