import { initialState } from "./state";
import { storage as LocalStorage } from "services/config/storage";

export const iroTest = (state = initialState, action) => {
  switch (action.type) {
    case "SET_PASSAGE": {
      return {
        ...state,
        passage: action.data,
      };
    }
    case "SET_QUESTIONS_LIST": {
      return {
        ...state,
        questionsList: action.data,
      };
    }
    case "SET_TRACK_LIST": {
      return {
        ...state,
        trackList: action.data,
      };
    }
    case "SET_PASSAGE_HAS_NEXT": {
      return {
        ...state,
        has_next: action.data,
      };
    }
    case "DECREASE_IRO_TIMER": {
      LocalStorage.destroy.reduxPersist();

      const timerSeconds =
        state.timerSeconds === 0 ? 59 : state.timerSeconds - 1;
      const timerMinutes =
        state.timerSeconds === 0 ? state.timerMinutes - 1 : state.timerMinutes;
      if (
        state.timerSeconds >= 0 &&
        state.timerMinutes >= 0 &&
        timerMinutes !== -1
      ) {
        return {
          ...state,
          timerMinutes,
          timerSeconds,
        };
      } else {
        return { ...state };
      }
    }
    case "RESET_IRO_TIMER": {
      console.log(action.data);
      return {
        ...state,
        timerMinutes: action.data.timerMinutes,
        timerSeconds: action.data.timerSeconds,
      };
    }
    case "SET_PASSAGE_NEXT_PAGE": {
      return {
        ...state,
        next_page: action.data,
      };
    }
    case "UPDATE_QUESTION_OPTION": {
      return {
        ...state,
        questionsList: state.questionsList.map((question) => {
          if (question.id === action.data.question_id) {
            return {
              ...question,
              selected_option: action.data.selected_option,
              selected_text: action.data.selected_text,
              selected_img: action.data.selected_img,
            };
          }
          return question;
        }),
      };
    }
    default:
      return state;
  }
};
